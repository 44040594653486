// eslint-disable-next-line import/no-unresolved
import code from '@lekoarts/gatsby-theme-minimal-blog/src/styles/code'

const newCode = {
  ...code,
  '.prism-code': {
    fontSize: '0.85rem',
    padding: '2rem 1rem 1rem 1rem',
    webkitOverflowScrolling: 'touch',
    backgroundColor: 'transparent',
    minWidth: '100%',
    mb: 0,
    mt: 0,
    overflow: 'auto',
    '&[data-linenumber="false"]': {
      '.token-line': {
        pl: 3,
      },
    },
  },
  'p > code, li > code': {
    bg: 'gray.2',
    color: 'gray.8',
    px: 2,
    py: 1,
    borderRadius: '2px',
    fontSize: '0.85rem',
  },
}

export default newCode
